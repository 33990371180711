/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  function responsive_fixes() {
    $('.preserve-height').responsiveEqualHeightGrid();

    if ($(window).width() < 768) {

      if ($('.menu-wrapper').not(':has(.category-links)')) {
        $('.menu-wrapper').prepend($('.category-links'));

        $('.category-links > div > ul > li').each(function () {
          var elClass = $(this).attr('class').split(' ')[0];

          if (elClass !== '') {
            $(this).append($('.menu-dropdown.' + elClass));
          }
        });
      }

    } else {

      if ($('.menu-wrapper:has(.category-links)')) {
        $('.category-links').insertAfter($('.logo-wrapper'));

        $('.category-links > div > ul > li').each(function () {
          var elClass = $(this).attr('class').split(' ')[0];

          if (elClass !== '') {
            $('.menu-dropdown.' + elClass).insertAfter('header');
          }
        });
      }

    }
  }
  var Sage = {
    // All pages
    'common': {
      init: function () {

        /* $(parent.window.document).scroll(function() {
          alert("bottom!");
        }); */


        // JavaScript to be fired on all pages
        $(document).scroll(function () {
          /* To top */
          if ($(this).scrollTop()) {
            $('#toTop').fadeIn();
          } else {
            $('#toTop').fadeOut();
          }

          if ($(this).scrollTop() > 100) {
            $("header").addClass("sticky");
          } else {
            $("header").removeClass("sticky");
          }
        });
        $("#toTop").on('click', function () {
          $("html, body").animate({ scrollTop: 0 }, 300);
        });
        $slideshow = $(".slider-for").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 4000,
          pauseOnHover: false,
          pauseOnFocus: false,
          focusOnSelect: false,
          fade: true,
          prevArrow: '<button type="button" class="slick-prev"><img src="/app/themes/genisys/dist/images/icons/prev.svg" alt="arrow left" /></button>',
          nextArrow: '<button type="button" class="slick-next"><img src="/app/themes/genisys/dist/images/icons/next.svg" alt="arrow right" /></button>',
        });
        $('.single-slide-img').on('click', function () {
          $slideshow.slick('slickNext');
        });


        $(".testimonial-car").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 4000,
          fade: true,
          centerMode: true,
          /* prevArrow: '<button type="button" class="slick-prev"><img src="/app/themes/genisys/dist/images/icons/prev.svg" alt="arrow left" /></button>', */
          prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left fa-2x text-white"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right fa-2x text-white"></i></button>',
        });

        $(".landing-testimonial").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 4000,
          fade: true,
          centerMode: true,
          prevArrow: $(".pp2"),
          nextArrow: $(".nn2"),
        });

        $('.knowus-carousel').slick({
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: false,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 4000,
          centerMode: true,
          responsive: [{
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          }, {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true

            }
          }, {
            breakpoint: 667,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true

            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true
            }
          }]
        });

        $('.partners-carousel').slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 4000,
          prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left fa-2x text-white"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right fa-2x text-white"></i></button>',
          /* centerMode: true, */
          responsive: [{
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          }, {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true

            }
          }, {
            breakpoint: 667,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true

            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true
            }
          }]
        });
        $('.story-carousel').slick({
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 4000,
          prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left fa-2x text-white"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right fa-2x text-white"></i></button>',
          responsive: [{
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          }, {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true

            }
          }, {
            breakpoint: 667,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true

            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true
            }
          }]
        });

        if ($(window).width() > 767) {

          $('#accordionBlog .card-header h3').each(function () {
            $(this).removeAttr('data-toggle');
          });
          $('#accordionBlog .collapse').each(function () {
            $(this).addClass('show');
          });
          $('.abt-feature').removeClass('partners-carousel');


        }


        /* Responsive menu */
        responsive_fixes();

        $('.menu-trigger').on('click', function (e) {
          e.preventDefault();

          if ($('.menu-wrapper').hasClass('active')) {
            $('.menu-wrapper').removeClass('active');
            $('.menu-trigger i').addClass('fa-bars-thin').removeClass('fa-times-thin');
          } else {
            $('.menu-wrapper').addClass('active');
            $('.menu-trigger i').addClass('fa-times-thin').removeClass('fa-bars-thin');
          }
        });

        $(document).on('change', '.up', function () {
          var names = [];
          var length = $(this).get(0).files.length;
          for (var i = 0; i < $(this).get(0).files.length; ++i) {
            names.push($(this).get(0).files[i].name);
          }
          // $("input[name=file]").val(names);
          if (length > 2) {
            var fileName = names.join(', ');
            $(this).closest('.input-group').find('.form-control').attr("value", length + " files selected");
          } else {
            $(this).closest('.input-group').find('.form-control').attr("value", names);
          }
        });

        /* Responsive Tab */
        $.fn.extend({
          easyResponsiveTabs: function (option) {
            //Set the default values, use comma to separate the settings, example:
            var defaults = {
              type: 'default', //default, vertical, accordion;
              width: 'auto',
              fit: true,
              closed: false,
              activate: function () { }
            };
            //Variables
            var options = $.extend(defaults, option);
            var opt = option,
              jtype = opt.type,
              jfit = opt.fit,
              jwidth = opt.width,
              vtabs = 'vertical',
              accord = 'accordion';

            //Events
            $(this).bind('tabactivate', function (e, currentTab) {
              if (typeof options.activate === 'function') {
                options.activate.call(currentTab, e);
              }
            });

            //Main function
            this.each(function () {
              var $respTabs = $(this);
              var $respTabsList = $respTabs.find('ul.resp-tabs-list');
              $respTabs.find('ul.resp-tabs-list li').addClass('resp-tab-item');
              $respTabs.css({
                'display': 'block',
                'width': jwidth
              });

              $respTabs.find('.resp-tabs-container > div').addClass('resp-tab-content');

              //Properties Function
              function jtab_options() {
                if (jtype === vtabs) {
                  $respTabs.addClass('resp-vtabs');
                }
                if (jfit === true) {
                  $respTabs.css({
                    width: '100%',
                    margin: '0px'
                  });
                }
                if (jtype === accord) {
                  $respTabs.addClass('resp-easy-accordion');
                  $respTabs.find('.resp-tabs-list').css('display', 'none');
                }
              }
              jtab_options();
              //Assigning the h2 markup to accordion title
              var $tabItemh2;
              $respTabs.find('.resp-tab-content').before("<h2 class='resp-accordion' role='tab'><span class='resp-arrow'></span></h2>");

              var itemCount = 0;
              $respTabs.find('.resp-accordion').each(function () {
                $tabItemh2 = $(this);
                var innertext = $respTabs.find('.resp-tab-item:eq(' + itemCount + ')').html();
                $respTabs.find('.resp-accordion:eq(' + itemCount + ')').append(innertext);
                $tabItemh2.attr('aria-controls', 'tab_item-' + (itemCount));
                itemCount++;
              });

              //Assigning the 'aria-controls' to Tab items
              var count = 0,
                $tabContent;
              $respTabs.find('.resp-tab-item').each(function () {
                $tabItem = $(this);
                $tabItem.attr('aria-controls', 'tab_item-' + (count));
                $tabItem.attr('role', 'tab');

                //First active tab, keep closed if option = 'closed' or option is 'accordion' and the element is in accordion mode 
                if (options.closed !== true && !(options.closed === 'accordion' && !$respTabsList.is(':visible')) && !(options.closed === 'tabs' && $respTabsList.is(':visible'))) {
                  $respTabs.find('.resp-tab-item').first().addClass('active');
                  $respTabs.find('.resp-accordion').first().addClass('active');
                  $respTabs.find('.resp-tab-content').first().addClass('resp-tab-content-active').attr('style', 'display:block');
                }

                //Assigning the 'aria-labelledby' attr to tab-content
                var tabcount = 0;
                $respTabs.find('.resp-tab-content').each(function () {
                  $tabContent = $(this);
                  $tabContent.attr('aria-labelledby', 'tab_item-' + (tabcount));
                  tabcount++;
                });
                count++;
              });

              //Tab Click action function
              $respTabs.find("[role=tab]").each(function () {
                var $currentTab = $(this);
                $currentTab.on('click', function () {

                  var $tabAria = $currentTab.attr('aria-controls');

                  if ($currentTab.hasClass('resp-accordion') && $currentTab.hasClass('active')) {
                    $respTabs.find('.resp-tab-content-active').slideUp('', function () {
                      $(this).addClass('resp-accordion-closed');
                    });
                    $currentTab.removeClass('active');
                    return false;
                  }
                  if (!$currentTab.hasClass('active') && $currentTab.hasClass('resp-accordion')) {
                    $respTabs.find('.active').removeClass('active');
                    $respTabs.find('.resp-tab-content-active').slideUp().removeClass('resp-tab-content-active resp-accordion-closed');
                    $respTabs.find("[aria-controls=" + $tabAria + "]").addClass('active');

                    $respTabs.find('.resp-tab-content[aria-labelledby = ' + $tabAria + ']').slideDown().addClass('resp-tab-content-active');
                  } else {
                    $respTabs.find('.active').removeClass('active');
                    $respTabs.find('.resp-tab-content-active').removeAttr('style').removeClass('resp-tab-content-active').removeClass('resp-accordion-closed');
                    $respTabs.find("[aria-controls=" + $tabAria + "]").addClass('active');
                    $respTabs.find('.resp-tab-content[aria-labelledby = ' + $tabAria + ']').addClass('resp-tab-content-active').attr('style', 'display:block');
                  }
                  //Trigger tab activation event
                  $currentTab.trigger('tabactivate', $currentTab);
                });
                //Window resize function                   
                $(window).resize(function () {
                  $respTabs.find('.resp-accordion-closed').removeAttr('style');
                });
              });
            });
          }
        });
        $('#horizontalTab').easyResponsiveTabs({
          type: 'default', //Types: default, vertical, accordion           
          width: 'auto', //auto or any width like 600px
          fit: true, // 100% fit in a container
          closed: 'accordion', // Start closed if in accordion view
          activate: function (event) { // Callback function if tab is switched
            var $tab = $(this);
            var $info = $('#tabInfo');
            var $name = $('span', $info);
            $name.text($tab.text());
            $info.show();
          }
        });
        $('#verticalTab').easyResponsiveTabs({
          type: 'vertical',
          width: 'auto',
          fit: true
        });
        /* Responsive Tab End */

        $('#country0-tab').on('click', function (e) {
          e.preventDefault();
          $('#country0').addClass('active show');
          $('#country1').removeClass('active show');
          $('#country2').removeClass('active show');
        });
        $('#country1-tab').on('click', function (e) {
          e.preventDefault();
          $('#country1').addClass('active show');
          $('#country0').removeClass('active show');
          $('#country2').removeClass('active show');
        });
        $('#country2-tab').on('click', function (e) {
          e.preventDefault();
          $('#country2').addClass('active show');
          $('#country1').removeClass('active show');
          $('#country0').removeClass('active show');
        });

        $('a[href*=\\#getstarted]').not('.btn-full-stack').on('click', function (e) {
          e.preventDefault();
          var offset = 100;
          var target = this.hash;
          if ($(this).data('offset') !== undefined) { offset = $(this).data('offset'); }
          $('html, body').stop().animate({
            'scrollTop': $(target).offset().top - offset
          }, 500, 'swing', function () {
            // window.location.hash = target;
          });
        });
        /* Landing Pages-----> Ad-operations, Full Stack, data-management, Qa & Testing */
        $('#platforms').on('click', function () {
          $(this).removeClass('text-muted');
          $('#campaigns').addClass('text-muted');
          $(".platforms").addClass("d-block");
          $(".platforms").removeClass("d-none");
          $(".campaigns").addClass("d-none");
          $(".campaigns").removeClass("d-block");
          /* Icon */
          $("#platforms .fa-angle-down").addClass("d-block");
          $("#platforms .fa-angle-down").removeClass("d-none");
          $("#platforms .fa-angle-right").addClass("d-none");
          $("#platforms .fa-angle-right").removeClass("d-block");

          $("#campaigns .fa-angle-right").addClass("d-block");
          $("#campaigns .fa-angle-right").removeClass("d-none");
          $("#campaigns .fa-angle-down").addClass("d-none");
          $("#campaigns .fa-angle-down").removeClass("d-block");

        });
        $('#campaigns').on('click', function () {
          $(this).removeClass('text-muted');
          $(".campaigns").addClass("d-block");
          $(".campaigns").removeClass("d-none");
          $(".platforms").addClass("d-none");
          $(".platforms").removeClass("d-block");
          $('#platforms').addClass('text-muted');
          /* Icons add remove */
          $("#campaigns .fa-angle-down").addClass("d-block");
          $("#campaigns .fa-angle-down").removeClass("d-none");
          $("#campaigns .fa-angle-right").addClass("d-none");
          $("#campaigns .fa-angle-right").removeClass("d-block");

          $("#platforms .fa-angle-right").addClass("d-block");
          $("#platforms .fa-angle-right").removeClass("d-none");
          $("#platforms .fa-angle-down").addClass("d-none");
          $("#platforms .fa-angle-down").removeClass("d-block");

        });

        if (window.location.hash === '#case-studies') {
          $("#blogCarousel").addClass("v-hidden");
          $("#casestudiesCarousel").removeClass("v-hidden");

          $("#casestudiescontent .fa-angle-down").addClass("d-block");
          $("#casestudiescontent .fa-angle-down").removeClass("d-none");
          $("#casestudiescontent .fa-angle-right").addClass("d-none");
          $("#casestudiescontent .fa-angle-right").removeClass("d-block");

          $("#blogs .fa-angle-right").addClass("d-block");
          $("#blogs .fa-angle-right").removeClass("d-none");
          $("#blogs .fa-angle-down").addClass("d-none");
          $("#blogs .fa-angle-down").removeClass("d-block");

        }

        $('#blogs').on('click', function () {
          $("#blogs .fa-angle-down").addClass("d-block");
          $("#blogs .fa-angle-down").removeClass("d-none");
          $("#blogs .fa-angle-right").addClass("d-none");
          $("#blogs .fa-angle-right").removeClass("d-block");

          $("#casestudiescontent .fa-angle-right").addClass("d-block");
          $("#casestudiescontent .fa-angle-right").removeClass("d-none");
          $("#casestudiescontent .fa-angle-down").addClass("d-none");
          $("#casestudiescontent .fa-angle-down").removeClass("d-block");
        });
        $('#casestudiescontent').on('click', function () {
          $("#casestudiescontent .fa-angle-down").addClass("d-block");
          $("#casestudiescontent .fa-angle-down").removeClass("d-none");
          $("#casestudiescontent .fa-angle-right").addClass("d-none");
          $("#casestudiescontent .fa-angle-right").removeClass("d-block");

          $("#blogs .fa-angle-right").addClass("d-block");
          $("#blogs .fa-angle-right").removeClass("d-none");
          $("#blogs .fa-angle-down").addClass("d-none");
          $("#blogs .fa-angle-down").removeClass("d-block");

        });/*  */

        /* Case Studies */
        $('#blogs').on('click',function () {
          $("#blogCarousel").removeClass("v-hidden");
          $("#casestudiesCarousel").addClass("v-hidden");
        });
        $('#casestudiescontent').on('click',function () {
          $("#blogCarousel").addClass("v-hidden");
          $("#casestudiesCarousel").removeClass("v-hidden");
        });
        $('.forblogs, .forcasestudies').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 5000,
          arrows: true,
          dots: false,
          pauseOnHover: false,
          responsive: [{
            breakpoint: 768,
            settings: {
              slidesToShow: 2
            }
          }, {
            breakpoint: 520,
            settings: {
              slidesToShow: 1
            }
          }]
        });
        $('.forsnapshot').slick({
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          arrows: true,
          dots: false,
          pauseOnHover: false,
          centerMode: true,
          responsive: [{
          breakpoint: 768,
          settings: {
              slidesToShow: 2
          }
          }, {
          breakpoint: 520,
          settings: {
              slidesToShow: 1
          }
          }]
      });


        /* Adops faq */
        $('#vert-tabs-tab a, #v-pills-tab a').on('click', function(e) {
          e.preventDefault();
          $(this).tab('show');
          var theThis = $(this);
          $('#vert-tabs-tab a, #v-pills-tab a').removeClass('active');
          theThis.addClass('active');
        });




        AOS.init();
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
        $(".industries-caraousel").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 4000,
          fade: true,
          prevArrow: '<button type="button" class="slick-prev"><img src="/app/themes/genisys/dist/images/icons/prev.svg" alt="arrow left" /></button>',
          nextArrow: '<button type="button" class="slick-next"><img src="/app/themes/genisys/dist/images/icons/next.svg" alt="arrow right" /></button>',
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about': {
      init: function () {
        // JavaScript to be fired on the about us page
        $('.history-carousel').slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 8000,
          centerMode: true,
            prevArrow: '<button type="button" class="slick-prev"><img src="/app/themes/genisys/dist/images/icons/prev.svg" alt="arrow left" /></button>',
            nextArrow: '<button type="button" class="slick-next"><img src="/app/themes/genisys/dist/images/icons/next.svg" alt="arrow right" /></button>',
          responsive: [{
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true
            }
          }, {
            breakpoint: 667,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true
            }
          }]
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common'); 

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.






